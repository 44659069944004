<template>
  <div v-if="entry.service">
    <h5 class="flix-html-h5">{{ entry.service.title }}</h5>
    <p class="flix-html-small">{{ entry.service.description }}</p>
    <p class="flix-html-small flix-html-strong">{{ getServiceTime(entry) }}<span v-if="entry.service.price">, {{ entry.service.price }} &euro;</span></p>
  </div>
</template>

<script>
export default {
  props: { entry: Object },
  methods: {
    getServiceTime (entry) {
      var e = entry.service.time

      if ((e * 1) < 15) {
        e = e * 60
      }

      e = e / 60

      if (e >= 1) {
        return e + ' ' + this.$t('message.hours')
      }

      e = e * 60
      return e + ' ' + this.$t('message.minutes')
    }
  }
}
</script>
